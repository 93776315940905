// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgSendDiagonal = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.336 12.743 11.552 19 19.54 6.298zm11.638-9.919L1.842 7.164a.75.75 0 0 0-.228 1.377l7.22 4.338 1.607 8.268a.75.75 0 0 0 1.371.257l10.97-17.442a.75.75 0 0 0-.06-.898.75.75 0 0 0-.748-.24M18.68 5.07l-9.204 6.445-5.464-3.282z"
      clipRule="evenodd"
    />
  </svg>
);
export const SendDiagonalIcon = forwardRef(SvgSendDiagonal);
